<template>
  <div class="paystatus">
    <!-- <Top/> -->
    <div class="icons">
      <div @click="back()">&lt;</div>
      <h1>浙江自然博物院收银台</h1>
    </div>
    <div class="icon">
      <img src="../../public/img/沙漏@2x.png" alt="" />
      <h2>支付失败</h2>
      <p>失败原因:请检查手机状态后支付或与前台联系</p>
    </div>
    <div class="last">
      <button>关闭页面</button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped>
.icons {
  width: 100%;
  height: 8vh;
  background-color: white;
  font-size: 0.3rem;
  margin: auto;
  text-align: center;
  line-height: 8vh;
  margin-bottom: 0.2rem;
  display: flex;
}
h1 {
  font-size: 0.2rem;
  margin: auto;
}
.icon {
  width: 100%;
  height: 2rem;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
img {
  width: 0.6rem;
  height: 0.6rem;
  margin: auto;
}
h2 {
  font-size: 0.2rem;
  margin: auto;
}
p {
  font-size: 0.16rem;
  margin: auto;
}
.last {
  width: 100%;
  height: 1rem;
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-around;
  margin-top: 0.5rem;
}
button {
  width: 1.18rem;
  height: 0.43rem;
  border-radius: 20px;
  border: 1px solid #6fb6b6;
  color: #6fb6b6;
  background-color: white;
}
</style>